<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.installOrderDetail')"
    id="installOrderDetail"
  >
    <div id="outer-title">{{ $t("i18n.installOrderDetail") }}</div>
    <div class="flex introduce">
      <div class="left text-center">
        <img
          src="../../../public/images/elevator_closed02.png"
          style="width: 70px"
        />
        <p class="">{{ detail.elevatorNumber }}</p>
      </div>
      <div class="right">
        <h4>{{ detail.projectName }}</h4>
        <p class="mt40">
          {{ $t("i18n.orderNumber") }}：{{ detail.orderNumber }}
        </p>
        <p class="m-t">
          {{ $t("i18n.maintenanceContractNo") }}：
          {{ detail.contractNumber }}
        </p>
      </div>
    </div>
    <div class="discuss" v-for="list in detail.assets" :key="list">
      <div class="title">
        {{ list.operateName }}<span class="fr">{{ list.submitTime }}</span>
      </div>
      <div class="list">
        <div class="item flex" v-for="item in list.resource" :key="item.id">
          <div class="left">
            <i class="el-icon-s-custom"></i>
            <span class="name">{{ item.createdBy }}</span>
          </div>
          <div class="middle">
            <p class="content" v-if="item.auditResult === null">
              {{ item.verbalProof }}
            </p>
            <p class="content" v-else>
              {{ $t("i18n.auditOpinion") }}：{{ item.auditRecord }}
            </p>
            <div class="file">
              <el-image
                :lazy="true"
                style="width: 100px"
                v-for="img in item.imgList"
                :key="img"
                :src="img"
                :preview-src-list="item.imgList"
              ></el-image>
              <a
                v-for="file in JSON.parse(item.fileProof)"
                :key="file"
                :href="$defined.imgFormalUrl + file.attachment_file + '&fileName=' + file.attachment_filename"
              >
                {{ file.attachment_filename }}
              </a>
            </div>
            <p class="gray">{{ item.createdDate }}</p>
          </div>
          <div class="right text-right">
            <span class="success" v-if="item.auditResult === null"></span>
            <span class="success" v-else-if="item.auditResult">
              {{ $t("i18n.adopt") }}
            </span>
            <span class="fail" v-else>{{ $t("i18n.Failed1") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "OrderDetail",
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const state = reactive({
      detail: "",
    });

    const init = async () => {
      let id = route.query.id;
      let { data } = await proxy.$api.system.getInstallDetail(id);
      data.assets.map((list) => {
        list.resource.map((item) => {
          let arr = [];
          if (item.pictureProof) {
            JSON.parse(item.pictureProof).map((img) => {
              arr.push(proxy.$defined.imgFormalUrl + img.attachment_file + "&fileName="+img.attachment_filename);
            });
            item.imgList = arr;
          }
        });
      });
      state.detail = data;
    };

    init();

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss">
#installOrderDetail {
  font-size: 16px;
  .title,
  h4 {
    font-size: 20px;
  }
  .introduce {
    .left {
      width: 200px;
    }
  }
  .discuss {
    .gray {
      color: $gray_color;
    }
    .title {
      background: $gray_color;
      color: $white;
      padding: 0 15px;
      height: 40px;
      line-height: 40px;
      margin: 20px 0 0;
    }
    .item {
      justify-content: space-between;
      padding: 20px 15px;
      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }
      i {
        display: block;
        font-size: 30px;
        margin: 5px 0;
        color: $nine_color;
        text-align: center;
      }
      .content {
        height: 50px;
      }
      .middle {
        width: 100%;
        .file {
          margin-bottom: 10px;
          a {
            display: block;
            font-size: 14px;
            background: rgba($gray_color, 0.5);
            color: $btn_reset_bg;
            max-width: 400px;
            width: 50%;
            padding: 5px 10px;
            margin-bottom: 10px;
          }
          .el-image {
            margin: 0 10px 10px 0;
          }
        }
      }
      .left,
      .right {
        width: 80px;
        flex-shrink: 0;
      }
      .success {
        color: $btn_add_bg;
      }
      .fail {
        color: $red_color;
      }
    }
  }
}
</style>
